import MontaClient from "app/features/common/MontaClient";
import Config from "app/features/common/util/Config";
import UserDTO from "lib/user/model/UserDTO";

export default class UserApi {
    private static montaClient = new MontaClient(Config.apiBaseUrl!);

    static async getMe(): Promise<UserDTO> {
        return this.montaClient.get("/api/v1/user/me");
    }
}
