import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

export const languages = {
    da: "Dansk",
    de: "Deutsch",
    en: "English",
    en_US: "English (US)",
    es: "Español",
    fr: "Français",
    it: "Italiano",
    sv: "Svenska",
};

void i18n
    .use(initReactI18next)
    .use(
        resourcesToBackend((language: string) => {
            return import(`../assets/locale/${language}.json`);
        })
    )
    .init({
        fallbackLng: "en",
        supportedLngs: Object.keys(languages),
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
