import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useModal, { ModalType } from "app/hooks/useModal";
import SettingsLanguageSelect from "lib/settings/components/SettingsLanguageSelect";
import SettingsPowerDisplayModeSelect from "lib/settings/components/SettingsPowerDisplayModeSelect.tsx";
import SettingsResetButton from "lib/settings/components/SettingsResetButton";
import SettingsThemeModeSelect from "lib/settings/components/SettingsThemeModeSelect";
import SettingsUserAvatar from "lib/settings/components/SettingsUserAvatar";
import React from "react";
import { useTranslation } from "react-i18next";

function SettingsModal() {
    const { isVisible, hideModal } = useModal(ModalType.Settings);
    const { t } = useTranslation();

    return (
        <>
            <Dialog fullWidth open={isVisible} onClose={hideModal}>
                <DialogTitle align="center">{t("settings.settings")}</DialogTitle>
                <DialogContent>
                    <SettingsUserAvatar fullMode />
                    <Box sx={{ mt: 1 }} />
                    <SettingsLanguageSelect />
                    <SettingsThemeModeSelect />
                    <SettingsPowerDisplayModeSelect />
                    <SettingsResetButton />
                </DialogContent>
                <DialogActions>
                    <Button onClick={hideModal}>{t("common.close")}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SettingsModal;
