import { ModalType } from "app/hooks/useModal";
import { AnalyticsUtil } from "app/util/AnalyticsUtil";
import { useSearchParams } from "react-router-dom";

const useShowModal = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const showModal = (modalType: ModalType) => {
        AnalyticsUtil.modalShown(modalType);
        searchParams.set("modal", modalType);
        setSearchParams(searchParams);
    };

    return {
        showModal,
    };
};

export default useShowModal;
