import { UserContext } from "lib/user/provider/UserContext";
import { useContext } from "react";

export function useUserContext() {
    const userContext = useContext(UserContext);
    if (!userContext) {
        throw new Error("useUserContext must be use inside UserContextProvider");
    }
    return userContext;
}
