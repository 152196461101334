import MontaResponseDTO from "app/features/common/model/MontaResponseDTO";

export default class MontaException extends Error {
    statusCode = 200;
    montaResponse?: MontaResponseDTO = undefined;

    constructor(m: string) {
        super(m);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
