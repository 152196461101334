import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MontaAppRouter from "app/MontaAppRouter.tsx";
import SettingsProvider from "lib/settings/provider/SettingsProvider";
import UserProvider from "lib/user/provider/UserProvider";
import { SnackbarProvider } from "notistack";
import React from "react";

const queryClient = new QueryClient();

function App() {
    // QueryClientProvider is a provider for the react-query library
    return (
        <QueryClientProvider client={queryClient}>
            {/* SnackbarProvider is a provider for the notistack library */}
            <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                <SettingsProvider>
                    <UserProvider>
                        <MontaAppRouter />
                    </UserProvider>
                </SettingsProvider>
            </SnackbarProvider>
        </QueryClientProvider>
    );
}

export default App;
