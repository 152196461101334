import MontaException from "app/features/common/model/MontaException";
import AuthRepository from "app/features/common/repository/AuthRepository";

export default class MontaClient {
    baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    async createRequest(
        method: string,
        path: string,
        data: any | undefined | null = undefined,
        accept: string = "application/json"
    ): Promise<Response> {
        let bodyData = undefined;

        const headers: [string, string][] = [["Accept", accept]];

        if (data) {
            headers.push(["Content-Type", "application/json"]);
            bodyData = JSON.stringify(data);
        }

        if (AuthRepository.getAccessToken()) {
            headers.push(["Authorization", "Bearer " + AuthRepository.getAccessToken()]);
        }

        const impersonationIdentityId = AuthRepository.getImpersonationIdentityId()?.toString();

        if (impersonationIdentityId) {
            headers.push(["Identity-Id", impersonationIdentityId]);
        }

        const response = await fetch(this.baseUrl + path, {
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: headers,
            redirect: "follow",
            referrerPolicy: "no-referrer",
            method: method,
            body: bodyData,
        });

        if (!response.ok) {
            await this.parseError(response);
        }

        return response;
    }

    async createJsonRequest(method: string, path: string, data: any | undefined = undefined): Promise<any> {
        return (await this.createRequest(method, path, data)).json();
    }

    async post(path: string, data: any) {
        return this.createJsonRequest("POST", path, data);
    }

    async get(path: string) {
        return this.createJsonRequest("GET", path, null);
    }

    async parseError(response: Response) {
        const err = new MontaException("HTTP status code: " + response.status);
        err.statusCode = response.status;
        err.montaResponse = await response.json();
        throw err;
    }
}
