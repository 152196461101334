import * as Sentry from "@sentry/react";
import App from "app/App";
import ErrorPage from "app/pages/ErrorPage.tsx";
import { AnalyticsUtil } from "app/util/AnalyticsUtil";
import React from "react";
import ReactDOM from "react-dom/client";
import "app/i18n";
import { BrowserRouter } from "react-router-dom";

AnalyticsUtil.init();

const ErrorBoundaryWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
        <Sentry.ErrorBoundary
            fallback={({ error }) => {
                return <ErrorPage type="500" error={error} />;
            }}
            onError={(error: unknown, componentStack: string | undefined, eventId: string) => {
                console.log("ErrorBoundary caught an error:", error, componentStack, eventId);
                if (
                    (error as Error).message.includes("Failed to fetch dynamically imported module") ||
                    (error as Error).message.includes("Importing a module script failed")
                ) {
                    Sentry.addBreadcrumb({
                        category: "debug",
                        message: "Reloading page due to failed module import",
                        data: {
                            href: window.location.href,
                            error: (error as Error).message,
                        },
                    });
                    window.location.reload();
                }
            }}
            showDialog
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <BrowserRouter>
            <ErrorBoundaryWrapper>
                <App />
            </ErrorBoundaryWrapper>
        </BrowserRouter>
    </React.StrictMode>
);
