import * as Sentry from "@sentry/react";
import MontaException from "app/features/common/model/MontaException";
import getErrorMessage from "app/features/common/util/MontaResponseUtil";
import Utils from "app/features/common/util/Utils";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export default class ErrorUtil {
    /**
     * Handles errors and displays error message using snackbar.
     *
     * @param  e - The error object.
     * @param enqueueSnackbar - Function to enqueue the snackbar.
     * @param  interceptor - Optional function to intercept the exception.
     */
    static HandleError(
        e: unknown,
        enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
        interceptor?: (exception: MontaException) => boolean
    ) {
        console.log("Error", e);

        let errorMessage = "Unknown exception occurred";

        if (e instanceof MontaException) {
            if (ErrorUtil.checkPermission(e)) {
                return;
            }

            if (interceptor && interceptor(e)) {
                return;
            }

            const responseMessage = getErrorMessage(e.montaResponse);

            if (responseMessage) {
                errorMessage = responseMessage;
            }
        } else if (e instanceof Error) {
            Sentry.captureException(e);
            errorMessage = e.message;
        }

        enqueueSnackbar(errorMessage, { variant: "error" });
    }

    /**
     * Checks the permission of a MontaException.
     *
     * @param {MontaException} montaException - The MontaException to check.
     *
     * @return {boolean} Returns true if the MontaException has a statusCode of 401, false otherwise.
     */
    static checkPermission(montaException: MontaException): boolean {
        if (montaException.statusCode === 401) {
            window.location.href = Utils.getKratosLoginUrl();
            return true;
        }

        return false;
    }
}
