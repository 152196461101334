import { useLocalStorage } from "lib/settings/hooks/useLocalStorage";
import { SettingsState } from "lib/settings/model/SettingsState";
import { SettingsContext } from "lib/settings/provider/SettingsContext";
import PowerDisplayMode from "lib/theme/PowerDisplayMode.ts";
import ThemeMode from "lib/theme/ThemeMode";
import React, { PropsWithChildren, useMemo } from "react";

const defaultSettings = {
    themeMode: ThemeMode.System,
    language: undefined,
    powerDisplayMode: PowerDisplayMode.Kw,
};

function SettingsProvider(props: PropsWithChildren<object>) {
    const values = useLocalStorage<SettingsState>("cpi_tool_settings", defaultSettings);

    const memoizedValue = useMemo(
        () => ({
            ...values.state,
            canReset: values.canReset,
            onReset: values.resetState,
            onUpdate: values.setState,
            onUpdateField: values.setField,
        }),
        [values.state, values.canReset, values.resetState, values.setState, values.setField]
    );

    return <SettingsContext.Provider value={memoizedValue}>{props.children}</SettingsContext.Provider>;
}

export default SettingsProvider;
