import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import { languages } from "app/i18n";
import { AnalyticsUtil } from "app/util/AnalyticsUtil";
import { useSettingsContext } from "lib/settings/hooks/useSettingsContext";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function SettingsLanguageSelect() {
    const { t, i18n } = useTranslation();
    const settingsContext = useSettingsContext();

    const languagesArray = Object.entries(languages);
    const [language, setLanguage] = useState<string>(() => {
        return settingsContext.language ?? i18n.language;
    });

    useEffect(() => {
        setLanguage(i18n.language);
    }, [i18n.language]);

    const onLanguageChange = (newLanguage: string) => {
        AnalyticsUtil.settingChanged("language", newLanguage);
        settingsContext.onUpdateField("language", newLanguage);
        void i18n.changeLanguage(newLanguage);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <FormControl fullWidth>
                <InputLabel id="language-select-label">{t("settings.language")}</InputLabel>
                <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={language}
                    label={t("settings.language")}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                        onLanguageChange(event.target.value);
                    }}
                >
                    {languagesArray.map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default SettingsLanguageSelect;
