import { GridSortItem } from "@mui/x-data-grid/models/gridSortModel";

export function getDefaultPageable(size: number = 10) {
    return new Pageable(size, 0, null);
}

export default class Pageable {
    size: number;
    page: number;
    sort: GridSortItem[] | null;

    constructor(size: number, page: number = 0, sort: GridSortItem[] | null) {
        this.size = size;
        this.page = page;
        this.sort = sort;
    }
}
