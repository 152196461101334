import { SettingsState } from "lib/settings/model/SettingsState";
import { createContext } from "react";

export type SettingsContextValue = SettingsState & {
    onReset: () => void;
    onUpdate: (updateValue: Partial<SettingsState>) => void;
    onUpdateField: (name: keyof SettingsState, updateValue: SettingsState[keyof SettingsState]) => void;
};

export const SettingsContext = createContext<SettingsContextValue | undefined>(undefined);
